import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {

    monthFormatter (value) {
      return moment(value).format('Y-MM')
    },

    dateLongFormatter (value) {
      return moment(value).format('MMMM DD, YYYY')
    },

    dateFormatter (value) {
      return moment(value).format('Y-MM-DD')
    },

    dateTimeLongFormatter (value) {
      return moment(value).format('MMMM DD, YYYY @ LT')
    },

    dateTimeShortFormatter (value) {
      return moment(value).format('MMM DD, YYYY @ LT')
    },

    dateTimeFormatter (value) {
      return moment(value).format('Y-MM-DD HH:mm:ss')
    },

    dateTimeLocalFormatter (value) {
      return moment(value).format('YYYY-MM-DDTHH:mm')
    },

    numberFormatter (value) {
      return numeral(value).format('0,0.00')
    },

    objectToUrl (objects) {
      return Object.keys(objects).map(
        key => (typeof objects[key] === 'undefined' || objects[key] === 'null' || objects[key] === null) ? `${key}=` : `${key}=${objects[key]}`
      ).join('&').toString()
    },

    findValueOfKey (arrays, value) {
      const index = arrays.findIndex(search => search.value === value)
      if (index >= 0) {
        return arrays[index].text
      }
      return null
    },

    dataToComposition (composition, is_parent = 0) {
      const data = {
        id: composition?.id || null,
        type: composition?.type_id || null,
        type_name: composition?.type_name || null,
        category: composition?.category_id || null,
        category_name: composition?.category_name || null,
        supplier: composition?.supplier_id || null,
        supplier_name: composition?.supplier_name || null,
        brand: composition?.brand_id || null,
        brand_name: composition?.brand_name || null,
        status: composition?.status_id || null,
        status_name: composition?.status_name || null,
        item_name: composition?.item_name || null,
        serial_number: composition.serial_number,
        purchase_date: composition.purchase_date,
        original_cost: composition.original_cost,
        specifications: composition.specifications,
        device_name: composition?.device_name || null,
        mac_address: composition?.mac_address || null,
        sap_asset_code: composition?.sap_asset_code || null,
        is_required: Number(composition.is_required),
        is_removed: 0,
        is_parent: Number(is_parent),
        active: Number(composition.active)
      }

      if (data.is_parent === 0) {
        data.item = composition?.item_id || null
        data.item_detail_name = composition?.item_detail_name || null
      }

      return data
    },

    employmentStatus (action) {
      let variant = 'success'

      switch (action) {
        case 'Trainee': {
          variant = 'warning'
          break
        }
        case 'Resigned': {
          variant = 'danger'
          break
        }
        case 'Probationary': {
          variant = 'info'
          break
        }
        default: {
          variant = 'success'
          break
        }
      }

      return variant
    },

    itemAction (action) {
      let variant = 'success'

      switch (action) {
        case 'Created': {
          variant = 'info'
          break
        }
        case 'Assigned': {
          variant = 'danger'
          break
        }
        default: {
          variant = 'success'
          break
        }
      }

      return variant
    },

    itemDetailAction (action) {
      let variant = 'success'

      switch (action) {
        case 'Created': {
          variant = 'info'
          break
        }
        case 'Detached': {
          variant = 'danger'
          break
        }
        case 'Swapped': {
          variant = 'warning'
          break
        }
        case 'Assigned': {
          variant = 'danger'
          break
        }
        default: {
          variant = 'success'
          break
        }
      }

      return variant
    }
  }
}
